import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import BootstrapVue from 'bootstrap-vue-next';
import './assets/styles/reset.css';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-vue-next/src/styles/styles.scss';
import '@/assets/styles/main.scss'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faArrowRight,
    faArrowLeft,
    faRightFromBracket,
    faPen,
    faTrash,
    faThumbsDown,
    faThumbsUp,
    faCheck,
    faSquareCheck,
    faBookmark,
    faClock
} from '@fortawesome/free-solid-svg-icons'

library.add(faArrowRight, faArrowLeft, faRightFromBracket, faPen, faTrash, faThumbsDown, faThumbsUp, faCheck, faSquareCheck, faBookmark, faClock)

createApp(App)
    .use(router)
    .use(BootstrapVue)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
