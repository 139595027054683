import {createRouter, createWebHistory} from 'vue-router';
import routes from "@/router/routes";

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0, left: 0}
    }
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token') || '';

    if (!token) {
        if (to.meta.requiresToken) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});


export default router;
