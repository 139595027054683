const routes = [
    {
        path: '/',
        meta: {requiresToken: true, title: 'Home'},
        component: () => import('../layouts/MainLayout.vue'),
        children: [
            // {path: '/', name: 'home', meta: {requiresToken: true}, component: () => import('../pages/HomePage.vue')},
            {
                path: '/',
                name: 'page.questions',
                meta: {requiresToken: true, title: 'Create questions'},
                component: () => import('../pages/BuildQuestions.vue')
            },
            {
                path: '/question-set/:setId/question/:id',
                name: 'page.questions.sample',
                meta: {requiresToken: true, title: 'Review Question'},
                component: () => import('../pages/QuestionSample.vue')
            },
            {
                path: '/question-results/:setId',
                name: 'page.results',
                meta: {requiresToken: true, title: 'Answers and explanations'},
                component: () => import('../pages/ResultPage.vue')
            },
            {
                path: '/history',
                name: 'page.history',
                meta: {requiresToken: true, title: 'History'},
                component: () => import('../pages/History.vue')
            },
            {
                path: '/progress',
                name: 'page.progress',
                meta: {requiresToken: true, title: 'Progress'},
                component: () => import('../pages/Progress.vue')
            },
            {
                path: '/exam',
                name: 'page.exam',
                meta: {title: 'Create the Exam', key: 'Exam', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/subject',
                name: 'page.subject',
                meta: {title: 'Create the Subject', key: 'Subject', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/topic',
                name: 'page.topic',
                meta: {title: 'Create the Topic', key: 'Topic', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/subtopic',
                name: 'page.subtopic',
                meta: {title: 'Create the Subtopic', key: 'Subtopic', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/difficulty',
                name: 'page.difficulty',
                meta: {title: 'Difficulty', key: 'QuestionSetDifficulty', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/level-of-numericy',
                name: 'page.size',
                meta: {title: 'Level of numericy', key: 'QuestionSetSize', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/question-style',
                name: 'page.type',
                meta: {title: 'Question style', key: 'QuestionType', requiresToken: true},
                component: () => import('../pages/Manage/Dictionary.vue')
            },
            {
                path: '/support',
                name: 'page.support',
                meta: {requiresToken: true, title: 'Help'},
                component: () => import('../pages/Support.vue')
            },
            {
                path: '/users',
                name: 'page.users',
                meta: {requiresToken: true, title: 'Users'},
                component: () => import('../pages/Users.vue')
            },
            {
                path: '/subscription',
                name: 'page.subscription',
                meta: {requiresToken: true, title: 'Subscription'},
                component: () => import('../pages/SubscriptionPage.vue')
            },
            {
                path: '/subscription/success',
                name: 'page.subscription.success',
                meta: {
                    requiresToken: true,
                    title: 'Subscription',
                    status: 'success'
                },
                component: () => import('../pages/SubscriptionPage.vue')
            },
            {
                path: '/prompt',
                name: 'page.prompt',
                meta: {requiresToken: true, title: 'Create Prompt'},
                component: () => import('../pages/PromptPage.vue')
            },
            {
                path: '/prompt-version/:id',
                name: 'page.prompt-version',
                meta: {requiresToken: true, title: 'Create Prompt version'},
                component: () => import('../pages/PromptVersion.vue')
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../layouts/EmptyLayout.vue'),
        children: [
            {
                path: '/login',
                name: 'page.login',
                meta: {requiresToken: false},
                component: () => import('../pages/Login.vue')
            },
            {
                path: '/google-verify',
                name: 'page.google-verify',
                meta: {requiresToken: false},
                component: () => import('../pages/TokenVerify.vue')
            },
        ],
    },
]

export default routes